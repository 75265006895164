import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="center-content">
          <div className="text-container">
            <h1>Ever wonder what companies do with your data?</h1>
            <h2>So do we...</h2>
          </div>
        </div>
      </header>
      <footer>
        <a href="https://www.my-terms.com" target="_blank" rel="noopener noreferrer">
        © 2024
        </a>
      </footer>
    </div>
  );
}

export default App;
